import { createStore } from 'vuex'
import mainPageTabs from '@/store/background/MainPageTabs'

export default createStore({
  state: {
    background: {
      loginUser: null
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    mainPageTabs,
  }
})
