<template>
  <router-view/>
</template>

<script>

</script>

<style>

#app {
  /* font-family: 'PingFang SC','Helvetica Neue','Microsoft YaHei UI','Microsoft YaHei','Noto Sans CJK SC',Sathu,EucrosiaUPC,Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
