import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/background/Login.vue')
  },
  {
    path: '/background/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/background/Login.vue')
  },
  {
    path: '/background/mainPage',
    name: 'MainPage',
    component: () => import(/* webpackChunkName: "mainPage" */ '@/views/background/MainPage.vue'),
    children: [{
        path: '/background/home',
        name: 'MainPageHome',
        components: {
          MainPageHome: () => import(/* webpackChunkName: "home" */ '@/views/background/home/Home.vue')
        }
      },
      {
        path: '/background/user/userCenter',
        name: 'UserCenter',
        components: {
          UserCenter: () => import(/* webpackChunkName: "userCenter" */ '@/views/background/user/UserCenter.vue')
        }
      },
      {
        path: '/background/property/project/projectManager',
        name: '1001',
        components: {
          1001: () => import(/* webpackChunkName: "projectManager" */ '@/views/background/property/project/ProjectManager.vue')
        }
      },
      {
        path: '/background/property/project/buildingManager',
        name: '1002',
        components: {
          1002: () => import(/* webpackChunkName: "buildingManager" */ '@/views/background/property/project/BuildingManager.vue')
        }
      },
      {
        path: '//background/property/project/roomManager',
        name: '1003',
        components: {
          1003: () => import(/* webpackChunkName: "roomManager" */ '@/views/background/property/project/RoomManager.vue')
        }
      },
      {
        path: '/background/property/project/houseManager',
        name: '1011',
        components: {
          1011: () => import(/* webpackChunkName: "houseManager" */ '@/views/background/property/project/HouseManager.vue')
        }
      },
      {
        path: '/background/property/project/shopManager',
        name: '1012',
        components: {
            1012: () => import(/* webpackChunkName: "shopManager" */ '@/views/background/property/project/ShopManager.vue')
        }
      },
      {
        path: '/background/property/owner/ownerManager',
        name: '1021',
        components: {
          1021: () => import(/* webpackChunkName: "ownerManager" */ '@/views/background/property/lease/OwnerManager.vue')
        }
      },
      {
        path: '/background/property/contract/contractManager',
        name: '101',
        components: {
            101: () => import(/* webpackChunkName: "contractManager" */ '@/views/background/property/contract/ContractManager.vue')
        }
      },
      {
        path: '/background/property/lease/leaseManager',
        name: '201',
        components: {
            201: () => import(/* webpackChunkName: "leaseManager" */ '@/views/background/property/lease/LeaseManager.vue')
        }
      },
      {
        path: '/background/property/lease/livingPayManager',
        name: '211',
        components: {
            211: () => import(/* webpackChunkName: "livingPayManager" */ '@/views/background/property/lease/LivingPayManager.vue')
        }
      },
      {
        path: '/background/property/finance/financeManager',
        name: '301',
        components: {
            301: () => import(/* webpackChunkName: "financeManager" */ '@/views/background/property/finance/FinanceManager.vue')
        }
      },
      {
        path: '/background/property/service/propertyServiceManager',
        name: '401',
        components: {
            401: () => import(/* webpackChunkName: "propertyServiceManager" */ '@/views/background/property/service/PropertyServiceManager.vue')
        }
      },
      {
        path: '/background/property/lease/scanCode/ScanCodeReport',
        name: '6010',
        components: {
            6010: () => import(/* webpackChunkName: "scanCodeReport" */ '@/views/background/property/lease/ScanCodeReport.vue')
        }
      },
      {
        path: '/background/organization/organizationManager',
        name: '9001',
        components: {
          9001: () => import(/* webpackChunkName: "organizationManager" */ '@/views/background/organization/OrganizationManager.vue')
        }
      },
      {
        path: '/background/auth/role/roleManager',
        name: '9011',
        components: {
          9011: () => import(/* webpackChunkName: "roleManager" */ '@/views/background/auth/role/RoleManager.vue')
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  // const route = ['index', 'list'];
  // let isLogin = store.state.token;  // 是否登录
  // // 未登录状态；当路由到route指定页时，跳转至login
  // if (route.indexOf(to.name) >= 0) {
  //   if (isLogin == null) {
  //     router.push({ path: '/login', });
  //   }
  // }
  // // 已登录状态；当路由到login时，跳转至home 
  // console.log(to.name)
  // localStorage.setItem('routerName', to.name)
  // if (to.name === 'login') {
  //   if (isLogin != null) {
  //     router.push({ path: '/HomeMain', });;
  //   }
  // }
  if('index' == to.name) {
    router.push({ name: 'Login'});
  }
  else if('Login' != to.name && null == store.state.background.loginUser) {
    router.push({ name: 'Login'});
  }

  next();
})